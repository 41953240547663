<!-- 检查报告页面 -->
<template>
  <div class="app">
    <div class="loading" v-if="!showPrintButton"><img src="../public/loading.gif" /></div>
    <div ref="printBox" class="print-box" id="printBox">
    <div class="report-title">{{ channel_name }}<br class="just-for-print dbmgd-no-print" />贝贝乐VR视功能检查报告单</div>
    <table class="user-info">
      <tr>
        <td style="text-align: left;">姓名：{{ reportData.nickname }}</td>
        <td>性别：{{ reportData.gender == 1 ? '男' : '女' }}</td>
        <td>年龄：{{ reportData.age }}岁</td>
        <td style="text-align: right;">检查号：{{ reportData.number }}</td>
      </tr>
    </table>
      
    <!-- 表格一 -->
    <div class="card-title">一、视功能检查</div>
    <table class="table-class">
      <tr class="table-function-container-title">
        <td :rowspan="1 + ((ctrlTjShow.isTJAmplitude || ctrlTjShow.isTJSensitivity) ? 1 : 0)" colspan="3" class="item "  style="width: 42%;">
          <div>检查项目</div>
        </td>
        <td colspan="3" rowspan="1" class="item"  style="width: 42%;">检查结果</td>
        <td colspan="1" :rowspan="1 + ((ctrlTjShow.isTJAmplitude || ctrlTjShow.isTJSensitivity) ? 1 : 0)" class="item"  style="width: 16%;">
          <div>参考值</div>
        </td>
      </tr>
      <tr class="table-function-container-title" v-if="ctrlTjShow.isTJAmplitude || ctrlTjShow.isTJSensitivity">
        <td colspan="1" style="width: 14%;" class="item">OD</td>
        <td colspan="1" style="width: 14%;" class="item">OS</td>
        <td colspan="1" style="width: 14%;" class="item">OU</td>
      </tr>
      <tr class="table-function-container" v-if="ctrlTjShow.isTJAmplitude">
        <td rowspan="1" colspan="3" class="item" style="width: 42%;">
          <div>调节幅度</div>
        </td>
        <td rowspan="1" colspan="1" :class="`item ${reportData.od_amp >= reportData.ampStandardValue ? '' : 'highlight-down-text'}`">
          <div>{{ reportData.od_amp }}D</div>
        </td>
        <td rowspan="1" colspan="1" :class="`item ${reportData.os_amp >= reportData.ampStandardValue ? '' : 'highlight-down-text'}`">
          <div>{{ reportData.os_amp }}D</div>
        </td>
        <td rowspan="1" colspan="1" class="item">
          <div>-</div>
        </td>
        <td rowspan="1" colspan="1" class="item">
          <div>≥{{ reportData.ampStandardValue }} </div>
        </td>
      </tr>
      <!-- style="display: grid; grid-template-columns: 36.84% 63.16%;" -->
      <tr class="table-function-container" v-if="ctrlTjShow.isTJSensitivity">
        <td rowspan="3" colspan="1" class="item" style="width: 14%;">
          <div>调节灵敏度</div>
        </td>
        <td rowspan="1" colspan="2" class="item" v-if="ctrlTjShow.isTJCpm" style="width: 28%;">
          <div>cpm</div>
        </td>
        <td rowspan="1" colspan="1" class="item" v-if="ctrlTjShow.isTJCpm">
          <div
            :class="reportData.rightTJLMD[0] && reportData.rightTJLMD[0] < reportData.cmpStandardValue[1] ? 'highlight-down-text' : ''">
            {{ reportData.rightTJLMD.length > 0 ? (reportData.rightTJLMD[0] + 'cpm') : "-" }}</div>
          <div class="highlight-color cpm-font-size"
            v-if="reportData.rightTJLMD.length > 0 && reportData.rightTJLMD[3] != 0">{{
              cpmJFStatus[reportData.rightTJLMD[3]] }}</div>
        </td>
        <td rowspan="1" colspan="1" class="item" v-if="ctrlTjShow.isTJCpm">
          <div
            :class="reportData.leftTJLMD[0] && reportData.leftTJLMD[0] < reportData.cmpStandardValue[1] ? 'highlight-down-text' : ''">
            {{ reportData.leftTJLMD.length > 0 ? (reportData.leftTJLMD[0] + 'cpm') : "-" }}</div>
          <div class="highlight-color cpm-font-size"
            v-if="reportData.leftTJLMD.length > 0 && reportData.leftTJLMD[3] != 0">{{
              cpmJFStatus[reportData.leftTJLMD[3]] }}</div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJCpm" colspan="1">
          <div
            :class="reportData.bothTJLMD[0] && reportData.bothTJLMD[0] < reportData.cmpStandardValue[0] ? 'highlight-down-text' : ''">
            {{ reportData.bothTJLMD.length > 0 ? (reportData.bothTJLMD[0] + 'cpm') : "-" }}</div>
          <div class="highlight-color cpm-font-size"
            v-if="reportData.bothTJLMD.length > 0 && reportData.bothTJLMD[3] != 0">{{
              cpmJFStatus[reportData.bothTJLMD[3]] }}</div>
        </td>
        <td rowspan="3" class="item" colspan="1">
          <div>
            双眼 ≥{{ reportData.cmpStandardValue[0] }}<br><br>单眼 ≥{{ reportData.cmpStandardValue[1] }}
          </div>
        </td>
      </tr>
      <tr class="table-function-container" v-if="ctrlTjShow.isTJSensitivity">
        <td colspan="2" class="item" v-if="ctrlTjShow.isTJNervous">
          <div>紧张用时</div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJNervous" colspan="1">
          <div v-if="reportData.rightTJLMD.length == 0">-</div>
          <div v-else>{{ reportData.rightTJLMD[3] == 1 || reportData.rightTJLMD[3] == 3 ? "不通过" :
            reportData.rightTJLMD[3]
              == 4 ? "-" : reportData.rightTJLMD[1] + "s" }} </div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJNervous" colspan="1">
          <div v-if="reportData.leftTJLMD.length == 0">-</div>
          <div v-else>{{ reportData.leftTJLMD[3] == 1 || reportData.leftTJLMD[3] == 3 ? "不通过" :
            reportData.leftTJLMD[3]
              == 4 ? "-" : reportData.leftTJLMD[1] + "s" }} </div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJNervous" colspan="1">
          <div v-if="reportData.bothTJLMD.length == 0">-</div>
          <div v-else>{{ reportData.bothTJLMD[3] == 1 || reportData.bothTJLMD[3] == 3 ? "不通过" :
            reportData.bothTJLMD[3]
              == 4 ? "-" : reportData.bothTJLMD[1] + "s" }} </div>
        </td>
      </tr>
      <tr class="table-function-container" v-if="ctrlTjShow.isTJSensitivity">
        <td colspan="2" class="item" v-if="ctrlTjShow.isTJRelax">
          <div>放松用时</div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJRelax" colspan="1">
          <div v-if="reportData.rightTJLMD.length == 0">{{ "-" }}</div>
          <div v-else>{{ reportData.rightTJLMD[3] == 2 || reportData.rightTJLMD[3] == 3 ? "不通过" :
            reportData.rightTJLMD[3]
              == 4 ? "-" : reportData.rightTJLMD[2] + "s" }}</div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJRelax" colspan="1">
          <div v-if="reportData.leftTJLMD.length == 0">{{ "-" }}</div>
          <div v-else>{{ reportData.leftTJLMD[3] == 2 || reportData.leftTJLMD[3] == 3 ? "不通过" :
            reportData.leftTJLMD[3]
              == 4 ? "-" : reportData.leftTJLMD[2] + "s" }}</div>
        </td>
        <td class="item" v-if="ctrlTjShow.isTJRelax" colspan="1">
          <div v-if="reportData.bothTJLMD.length == 0">{{ "-" }}</div>
          <div v-else>{{ reportData.bothTJLMD[3] == 2 || reportData.bothTJLMD[3] == 3 ? "不通过" :
            reportData.bothTJLMD[3]
              == 4 ? "-" : reportData.bothTJLMD[2] + "s" }}</div>
        </td>
      </tr>

      <tr class="table-function-container" v-if="ctrlTjShow.isDbmgd">
        <td colspan="3" class="item" style="width: 42%;">
          <div>对比敏感度</div>
        </td>
        <td colspan="4" class="item"  style="width: 58%;">
          <div id="dbmgd_img" style="text-align: center;">
            <img id="imgCan" ref="printImg" :src="dbmgdSrc" class="dbmgd-normal" />
            <div class="cpm-font-size"><span>参考值：对应的数值在阴影范围内即为正常</span></div>
          </div>
        </td>
      </tr>


      <tbody class="table-binocular-container"
        v-if="ctrlBinocularShow.isBinocular1 || ctrlBinocularShow.isBinocular2 || ctrlBinocularShow.isBinocular3">
        <!-- 同时视 -->
        <tr v-if="ctrlBinocularShow.isBinocular1" >
          <td 
            :rowspan="(ctrlBinocularShow.isSkd ? 1 : 0) + (ctrlBinocularShow.isZdy ? 1 : 0) + (ctrlBinocularShow.isZjyw ? 2 : 0)"
            class="item" colspan="1" style="width: 14%;">
            <div>同时视</div>
          </td>
          <td colspan="2" class="item" v-if="ctrlBinocularShow.isSkd" style="width: 28%;">
            <div>四孔灯</div>
          </td>
          <td colspan="3" :class="`item ${reportData.four_lamp > 2 ? 'highlight-color' : ''}`"
            v-if="ctrlBinocularShow.isSkd"  style="width: 42%;">
            <div>{{ reportData | fourLampCountFilter }}个</div>
          </td>
          <td colspan="1" class="item" v-if="ctrlBinocularShow.isSkd" style="width: 16%;">
            <div>4</div>
          </td>
        </tr>
        <tr v-if="ctrlBinocularShow.isBinocular1">
          <td colspan="2" class="item" v-if="ctrlBinocularShow.isZdy" style="width: 28%;">
            <div>主导眼</div>
          </td>
          <td colspan="3" class="item" v-if="ctrlBinocularShow.isZdy" style="width: 42%;">
            <div>{{ reportData.leading_eye == '1' ? '左眼' : reportData.leading_eye == '2' ? '右眼' : '-' }}
            </div>
          </td>
          <td colspan="1" class="item" v-if="ctrlBinocularShow.isZdy" style="width: 16%;">
            <div>-</div>
          </td>
        </tr>
        <tr v-if="ctrlBinocularShow.isBinocular1 && ctrlBinocularShow.isZjyw">
          <td rowspan="2" colspan="1" class="item" style="width: 14%;">
            <div>知觉眼位</div>
          </td>
          <td rowspan="1" colspan="1" class="item" style="width: 14%;">
            <div>水平偏差</div>
          </td>
          <td colspan="3"  style="width: 42%;"
            :class="`item ${reportData.horizontal_influence < 0.1 && reportData.horizontal_influence > -0.1 ? '' : 'highlight-up-text'}`">
            <div>{{ reportData.horizontal_influence }}度</div>
          </td>
          <td rowspan="2" class="item" colspan="1" style="width: 16%;">
            <div>＜0.1</div>
          </td>
        </tr>
        <tr v-if="ctrlBinocularShow.isBinocular1 && ctrlBinocularShow.isZjyw">
          <td class="item"  colspan="1" style="width: 14%;" >
            <div>垂直偏差</div>
          </td>
          <td colspan="3"   style="width: 42%;"
            :class="`item ${reportData.vertical_influence < 0.1 && reportData.vertical_influence > -0.1 ? '' : 'highlight-up-text'}`">
            <div>{{ reportData.vertical_influence }}度</div>
          </td>
        </tr>
      </tbody>

      <!-- 融合视 -->
      <tbody class="table-binocular-container" v-if="ctrlBinocularShow.isBinocular2">
        <tr>
          <td rowspan="2" colspan="1" class="item" style="width: 14%;">
            <div>融合视</div>
          </td>
          <td rowspan="2" class="item" v-if="ctrlBinocularShow.isRhSjd"  colspan="1" style="width: 14%;">
            <div>随机点融合<br>(800弧秒)</div>
          </td>
          <td class="item" v-if="ctrlBinocularShow.isRhSjd"  colspan="1" style="width: 14%;">
            <div>散开范围</div>
          </td>
          <td colspan="3" :class="`item ${reportData.point_apart < -6.5 ? '' : 'highlight-down-text'}`" style="width: 42%;"
            v-if="ctrlBinocularShow.isRhSjd">
            <div>{{ reportData.point_apart }}度</div>
          </td>
          <td class="item" v-if="ctrlBinocularShow.isRhSjd" colspan="1" style="width: 16%;">
            <div>>6.5</div>
          </td>
        </tr>
        <tr v-if="ctrlBinocularShow.isRhSjd">
          <td class="item"  colspan="1" style="width: 14%;">
            <div>集合范围</div>
          </td>
          <td colspan="3" :class="`item ${reportData.point_focus > 15 ? '' : 'highlight-down-text'}`" style="width: 42%;">
            <div>{{ reportData.point_focus }}度</div>
          </td>
          <td class="item"  colspan="1" style="width: 16%;">
            <div>>15</div>
          </td>
        </tr>
      </tbody>


      <!-- 立体视 -->
      <tbody class="table-binocular-container" v-if="ctrlBinocularShow.isBinocular3">
        <tr>
          <td style="width: 14%;"
            :rowspan="(ctrlBinocularShow.isLtLine ? 1 : 0) + (ctrlBinocularShow.isLtStatic ? 1 : 0) + (ctrlBinocularShow.isLtDynamic ? 1 : 0)"
            class="item"  colspan="1" >
            <div>立体视</div>
          </td>
          <td colspan="2" class="item" v-if="ctrlBinocularShow.isLtLine" style="width: 28%;">
            <div>线条立体视</div>
          </td>
          <td colspan="3" :class="`item ${reportData.line_solid < 100 ? '' : 'highlight-up-text'}`"  style="width: 42%;"
            v-if="ctrlBinocularShow.isLtLine">
            <div>{{ reportData.line_solid }}弧秒</div>
          </td>
          <td
            :rowspan="(ctrlBinocularShow.isLtLine ? 1 : 0) + (ctrlBinocularShow.isLtStatic ? 1 : 0) + (ctrlBinocularShow.isLtDynamic ? 1 : 0)"
            class="item"  colspan="1"  style="width: 16%;">
            <div>≤100</div>
          </td>
        </tr>
        <tr v-if="ctrlBinocularShow.isLtStatic">
          <td class="item" colspan="2" style="width: 28%;">
            <div>静态随机点</div>
          </td>
          <td colspan="3" :class="`item ${reportData.s_random_point < 100 ? '' : 'highlight-up-text'}`"  style="width: 42%;">
            <div>{{ reportData.s_random_point }}弧秒</div>
          </td>
        </tr>
        <tr v-if="ctrlBinocularShow.isLtDynamic">
          <td class="item" colspan="2"  style="width: 28%;">
            <div>动态随机点</div>
          </td>
          <td colspan="3" :class="`item ${reportData.d_random_point < 100 ? '' : 'highlight-up-text'}`">
            <div>{{ reportData.d_random_point }}弧秒</div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 检查结果分析 -->
    <div class="card-title">二、检查结果分析</div>
    <table class="result-box">
      <!-- 调节幅度 -->
        <tr class="result-grid-contain" v-if="ctrlTjShow.isTJAmplitude">
          <td rowspan="2" colspan="3"  class="item align-center" style="width: 21%;">
            <div>调节幅度</div>
          </td>
          <td colspan="3" :class="reportData.od_amp | ampFilter(reportData.ampStandardValue, 2)" style="width: 21%;">
            右眼<br />{{
              reportData.od_amp | ampFilter(reportData.ampStandardValue, 0) }}
          </td>
          <td  class="item align-left"  style="width: 58%;"
            :rowspan="(reportData.od_amp >= reportData.ampStandardValue) != (reportData.os_amp >= reportData.ampStandardValue) ? 1 : 2">
            {{ reportData.od_amp | ampFilter(reportData.ampStandardValue, 1) }}
          </td>
        </tr>
        <tr v-if="ctrlTjShow.isTJAmplitude">
          <td colspan="3" :class="reportData.os_amp | ampFilter(reportData.ampStandardValue, 2)" style="width: 21%;">左眼<br />{{
            reportData.os_amp | ampFilter(reportData.ampStandardValue, 0) }}
          </td>
          <td  class="item align-left" style="width: 58%;"
            v-if="(reportData.od_amp >= reportData.ampStandardValue) != (reportData.os_amp >= reportData.ampStandardValue)">
            {{ reportData.os_amp | ampFilter(reportData.ampStandardValue, 1) }}
          </td>
        </tr>

      <!-- 调节灵敏度 -->
      <tbody class="result-grid-contain" v-if="ctrlTjShow.isTJSensitivity">
        <tr v-if="reportData.bothTJLMD.length > 0">
          <td  class="item align-center" colspan="3" :rowspan="1 + (reportData.rightTJLMD.length > 0 ? 1 : 0) + (reportData.leftTJLMD.length > 0 ? 1 : 0)"  style="width: 21%;">
            <div>调节灵敏度</div>
          </td>
          <td colspan="3" :class="reportData.bothTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[0], 2)" style="width: 21%;">
            双眼<br />调节灵敏度{{ reportData.bothTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[0], 0) }}
          </td>
          <td  class="item align-left" :rowspan="(tjlmdMerge01 || tjlmdMerge02) ? 2 : tjlmdMerge012 ? 3 : 1" style="width: 58%;">
            {{ reportData.bothTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[0], 1) }}
          </td>
        </tr>
        <tr v-if="reportData.rightTJLMD.length > 0">
          <td class="item align-center" colspan="3" v-if="reportData.bothTJLMD.length == 0"
            :rowspan="1 + (reportData.leftTJLMD.length > 0 ? 1 : 0)"  style="width: 21%;">
            <div>调节灵敏度</div>
          </td>
          <td colspan="3" :class="reportData.rightTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[1], 2)" style="width: 21%;">
            右眼<br />调节灵敏度{{ reportData.rightTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[1], 0) }}
          </td>
          <td  v-if="!tjlmdMerge01 && !tjlmdMerge012" class="item align-left" :rowspan="tjlmdMerge12 ? 2 : 1" style="width: 58%;">
            {{ reportData.rightTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[1], 1) }}
          </td>
        </tr>
        <tr v-if="reportData.leftTJLMD.length > 0">
          <td class="item align-center" colspan="3" v-if="reportData.bothTJLMD.length == 0 && reportData.rightTJLMD.length == 0"  style="width: 21%;">
            <div>调节灵敏度</div>
          </td>
          <td colspan="3" :class="reportData.leftTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[1], 2)" style="width: 21%;">
            左眼<br />调节灵敏度{{ reportData.leftTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[1], 0) }}
          </td>
          <td  v-if="!tjlmdMerge12 && !tjlmdMerge012 && !tjlmdMerge02" class="item align-left" style="width: 58%;">
            {{ reportData.leftTJLMD[0] | tjlmdFilter(reportData.cmpStandardValue[1], 1) }}
          </td>
        </tr>
      </tbody>

      <!-- 对比敏感度 -->
      <tbody class="result-grid-contain" v-if="ctrlTjShow.isDbmgd">
        <tr v-if="resText.double != ''">
          <td class="item align-center" colspan="3" :rowspan="1 + (resText.right != '' ? 1 : 0) + (resText.left != '' ? 1 : 0)"  style="width: 21%;">
            <div>对比敏感度</div>
          </td>
          <td colspan="3" v-if="resText.double != ''" :class="resText.double | dbmgdFilter(2)" style="width: 21%;">双眼{{ resText.double |
            dbmgdFilter(0)
          }}
          </td>
          <td  v-if="resText.double != ''" class="item align-left" style="width: 58%;">
            {{ resText.double }}
          </td>
        </tr>
        <tr v-if="resText.right != ''">
          <td colspan="3" v-if="resText.double == ''" :rowspan="1 + (resText.left != '' ? 1 : 0)"  style="width: 21%;">
            <div class="item align-center">对比敏感度</div>
          </td>
          <td colspan="3" v-if="resText.right != ''" :class="resText.right | dbmgdFilter(2)" style="width: 21%;">右眼{{ resText.right |
            dbmgdFilter(0) }}
          </td>
          <td  v-if="resText.right != ''" class="item align-left" style="width: 58%;">
            {{ resText.right }}
          </td>
        </tr>
        <tr v-if="resText.left != ''">
          <td colspan="3" v-if="resText.double == '' && resText.right == ''"  style="width: 21%;">
            <div class="item align-center">对比敏感度</div>
          </td>
          <td colspan="3" v-if="resText.left != ''" :class="resText.left | dbmgdFilter(2)" style="width: 21%;">左眼{{ resText.left |
            dbmgdFilter(0) }}
          </td>
          <td  v-if="resText.left != ''" class="item align-left" style="width: 58%;">
            {{ resText.left }}
          </td>
        </tr>
      </tbody>

      <!-- 三级视功能 -->
      <!-- 同时视 -->
      <tr class="result-grid-contain" v-if="ctrlBinocularShow.isBinocular1">
        <td colspan="3" class="item align-center"  style="width: 21%;">同时视功能</td>
        <td colspan="3" :class="reportData | fourLampFilter(2)"  style="width: 21%;">
          <div>{{ reportData | fourLampFilter(0) }}</div>
        </td>
        <td  class="item align-left" style="width: 58%;">
          {{ reportData | fourLampFilter(1) }}
        </td>
      </tr>
      <!-- 知觉眼位 -->
      <tr class="result-grid-contain" v-if="ctrlBinocularShow.isZjyw">
        <td colspan="3" class="item align-center" style="width: 21%;">知觉眼位</td>
        <td colspan="3" :class="reportData | zjywFilter(2)" style="width: 21%;">
          {{ reportData | zjywFilter(0) }}
        </td>
        <td  class="item align-left" style="width: 58%;">
          {{ reportData | zjywFilter(1) }}
        </td>
      </tr>

      <!-- 融合视-散开 -->
      <tr class="result-grid-contain" v-if="ctrlBinocularShow.isBinocular2">
        <td colspan="3" class="item align-center" style="width: 21%;">融合视功能-散开功能</td>
        <td colspan="3" :class="reportData | binocular2Filter('fk', 2)" style="width: 21%;">
          {{ reportData | binocular2Filter('fk', 0) }}
        </td>
        <td  class="item align-left" style="width: 58%;">
          {{ reportData | binocular2Filter('fk', 1) }}
        </td>
      </tr>

      <!-- 融合视-集合 -->
      <tr class="result-grid-contain" v-if="ctrlBinocularShow.isBinocular2">
        <td colspan="3" class="item align-center"  style="width: 21%;">融合视功能-集合功能</td>
        <td colspan="3" :class="reportData | binocular2Filter('fc', 2)" style="width: 21%;">
          {{ reportData | binocular2Filter('fc', 0) }}
        </td>
        <td  class="item align-left" style="width: 58%;">
          {{ reportData | binocular2Filter('fc', 1) }}
        </td>
      </tr>

      <!-- 立体视 -->
      <tr class="result-grid-contain" v-if="ctrlBinocularShow.isBinocular3">
        <td colspan="3" class="item align-center" style="width: 21%;">立体视功能</td>
        <td colspan="3" :class="reportData | binocular3Filter(2)" style="width: 21%;">
          {{ reportData | binocular3Filter(0) }}
        </td>
        <td  class="item align-left" style="width: 58%;">
          {{ reportData | binocular3Filter(1) }}
        </td>
      </tr>

    </table>


    <!-- 建议 -->
    <div class="just-for-print">
      <div class="card-title">三、建议</div>
      <div class="suggest-class">
        <div>
          <span style="width:90px;display: inline-block">视功能训练：</span>
          <span>□</span>3个月
          <span style="margin-left: 8px;">□</span>6个月
          <span style="margin-left: 8px;">□</span>12个月
        </div>
        <div>
          <span style="width:90px;display: inline-block">视功能模块：</span>
          <span>□</span>调节幅度
          <span style="margin-left: 8px;">□</span>调节灵敏度
          <span style="margin-left: 8px;">□</span>融合视
          <span style="margin-left: 8px;">□</span>立体视
          <span style="margin-left: 8px;">□</span>眼球运动
        </div>
      </div>

      <div class="report-footer">
          <span>检查时间：{{ reportData.create_time }}</span>
          <span v-html="'&nbsp;&nbsp;'"></span>
          <span>检查师：</span>
          <span  v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" v-if="!reportData.doctor_name || reportData.doctor_name == ''"></span>
          <span v-else>{{ reportData.doctor_name }}</span>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Print from './utils/print.js';
import { Dbmgd } from "./utils/dbmgd.js";
import html2pdf from 'html2pdf.js'
import axios from 'axios';
import Qs from 'qs' //引入数据格式化
export default {
  filters: {
    // 调节幅度数据处理mode: 0-状态，1-评估，2-颜色
    ampFilter(value, standard, mode) {
      if (value >= standard) {
        if (mode == 1) return "拥有正常的调节力，阅读与视物可以保持清晰且持久。"
        if (mode == 2) return "item align-center green-bg"
        return "调节幅度正常"
      } else {
        if (mode == 1) return "近距离阅读或工作时间较长时，会视物模糊、头疼、双影、阅读困难，易引发视疲劳导致近视度数加深。"
        if (mode == 2) return "item align-center orange-bg"
        return "调节不足"
      }
    },

    // 调节灵敏度数据处理
    tjlmdFilter(value, standard, mode) {
      if (value >= standard) {
        if (mode == 1) return "正常的调节灵敏度，能使人眼在改变注视距离时，迅速获得清晰的视网膜成像，提高学习及工作效率。"
        if (mode == 2) return "item align-center green-bg"
        return "正常";
      } else {
        if (mode == 1) return "学习工作中需要长时间近距离用眼，易感到阅读困难、困乏、远近聚焦转换困难，调节紧张或放松能力紊乱会导致近视度数加深。"
        if (mode == 2) return "item align-center orange-bg"
        return "不足";
      }
    },

    // 对比敏感度数据处理
    dbmgdFilter(value, mode) {
      if (value.indexOf('偏低')) {
        if (mode == 2) return "item align-center orange-bg"
        return "异常";
      } else {
        if (mode == 2) return "item align-center green-bg"
        return "正常";
      }
    },

    //同时视功能
    fourLampFilter(reportData, mode) {
      if (!reportData) return;
      let eye = reportData.leading_eye == 0 ? "交替抑制" : reportData.leading_eye == "1" ? "左眼" : "右眼"
      if (mode == 1) {
        switch (reportData.four_lamp) {
          case "3":
            return `主导眼为${eye}，同时视功能异常，存在左眼抑制`
          case "4":
            return `主导眼为${eye}，同时视功能异常，存在右眼抑制`
          case "5":
            return "同时视功能异常，存在交替抑制"
          case "1":
            return `主导眼为${eye}，同时视功能正常`
          case "2":
            return `主导眼为${eye}，同时视功能正常，但有复视，存在异常视网膜对应`
        }
      }
      if (reportData.four_lamp == "1" || reportData.four_lamp == "2") {
        if (mode == 2) return "item align-center green-bg"
        return "正常"
      } else {
        if (mode == 2) return "item align-center orange-bg"
        return "异常"
      }
    },

    //同时视功能
    fourLampCountFilter(reportData) {
      if (!reportData) return;
      switch (reportData.four_lamp) {
        case "3":
          return "2"
        case "4":
          return "3"
        case "5":
          return "2或3"
        case "1":
          return "4"
        case "2":
          return "5"
      }
    },


    // 知觉眼位功能
    zjywFilter(reportData, mode) {
      if (!reportData) return;
      if (reportData.horizontal_influence < 0.1 &&
        reportData.vertical_influence < 0.1 &&
        reportData.horizontal_influence > -0.1 &&
        reportData.vertical_influence > -0.1) {
        if (mode == 1) return "大脑视知觉水平对眼位控制能力正常。"
        if (mode == 2) return "item align-center green-bg"
        return "正常"
      } else if (reportData.horizontal_influence <= 1.5 &&
        reportData.vertical_influence <= 0.39 &&
        reportData.horizontal_influence >= -1.5 &&
        reportData.vertical_influence >= -0.39) {
        if (mode == 1) return "知觉眼位的偏差越大，说明大脑视知觉水平对眼位控制的能力越差，弱视程度越深，对双眼视觉功能的损害越大。"
        if (mode == 2) return "item align-center orange-bg"
        return "轻度异常"
      } else {
        if (mode == 1) return "知觉眼位的偏差越大，说明大脑视知觉水平对眼位控制的能力越差，弱视程度越深，对双眼视觉功能的损害越大。"
        if (mode == 2) return "item align-center red-bg"
        return "重度异常"
      }
    },

    // 融合视功能
    binocular2Filter(reportData, type, mode) {
      if (!reportData) return;
      if (type == "fk") {
        let apart = Math.min(...[reportData.line_apart, reportData.point_apart].map(item =>
          item === "" ? NaN : Number(item)).filter(item => !isNaN(item)));
        if (apart < -6.5) {
          if (mode == 1) return "拥有正常的眼球运动能力和眼位，阅读和视物较为舒适持久。"
          if (mode == 2) return "item align-center green-bg"
          return "正常"
        } else if (apart >= -6.5 && apart < 0) {
          if (mode == 1) return "长时间看远容易出现重影、模糊、视疲劳等，并可能伴有轻度头痛。"
          if (mode == 2) return "item align-center orange-bg"
          return "轻度异常"
        } else {
          if (mode == 1) return "看远很容易产生复视、视疲劳，同时伴有中重度头痛，在疲劳状态尤为明显。"
          if (mode == 2) return "item align-center red-bg"
          return "重度异常"
        }

      } else if (type == "fc") {
        let focus = Math.max(...[reportData.line_focus, reportData.point_focus].map(item =>
          item === "" ? NaN : Number(item)).filter(item => !isNaN(item)));
        if (focus > 15) {
          if (mode == 1) return "保持双眼单视，可以舒适的视物，并有助于精准判断距离和物体大小"
          if (mode == 2) return "item align-center green-bg"
          return "正常"
        } else if (focus <= 15 && focus > 0) {
          if (mode == 1) return "阅读和近距离工作稍久时，容易出现视物模糊，视疲劳，交叉性复视，头痛等不适。"
          if (mode == 2) return "item align-center orange-bg"
          return "轻度异常"
        } else {
          if (mode == 1) return "经常会因视物模糊、复视、头痛而放弃工作；阅读和近距离工作时会眼部不适，视物模糊，易视疲劳，交叉性复视，头痛。"
          if (mode == 2) return "item align-center red-bg"
          return "重度异常"
        }
      }
    },

    // 立体视功能
    binocular3Filter(reportData, mode) {
      if (!reportData) return;
      let lts = Math.min(...[reportData.line_solid, reportData.s_random_point,
      reportData.d_random_point].map(item => item === "" ? NaN : Number(item)).filter(item =>
        !isNaN(item)));
      if (lts <= 100) {
        if (mode == 1) return "能够精确判断物体的深度感和立体感，立体视功能的正常对巩固弱视治疗效果很有帮助，它也是衡量弱视是否治愈的指标之一。"
        if (mode == 2) return "item align-center green-bg"
        return "正常"
      } else if (lts >= 120 && lts < 800) {
        if (mode == 1) return "丧失部分立体感知能力，会对物体立体形状及不同物体远近位置感知存在失常，从而导致无法从事精细作业。"
        if (mode == 2) return "item align-center orange-bg"
        return "轻度异常"
      } else {
        if (mode == 1) return "没有立体视觉，会对空间距离的把握会出现偏差，从事驾驶和操作精密仪器等精细工作存在风险。"
        if (mode == 2) return "item align-center red-bg"
        return "重度异常"
      }

    },
  },
  created() {
    const url = new URL(window.location.href); // 或者直接使用 URL 字符串
    const params = new URLSearchParams(url.search);
    // 获取参数值
    const rid = params.get('rid');
    const token = params.get('token');
    const nickname = params.get('nickname');
    this.env = params.get('env');
    this.showReportById(rid, token);
  },
  computed: {

  },
  methods: {
    /**
     * 选择报告
     */
    async showReportById(rid, token) {
      try {
        // 发送 POST 请求
        // 准备要发送的参数
        const postData = {
          rid: rid
        };
        // 配置 headers
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`, // 自定义的 STICK 头
            'Content-Type': 'application/x-www-form-urlencoded' // 这里是常见的 Content-Type
          }
        };
        let baseUrl = this.env === 'release' ? 'https://api.huyantianshi.cn' : 'https://api-cs.huyantianshi.cn'
        const response = await axios.post((process.env.NODE_ENV === 'production' ? baseUrl : '') + '/glmp/report/detail', postData, config);
        if (response.data && response.data.data) {
          this.dealWithReportData(response.data.data)
        }
      } catch (error) {
        // 处理错误
        console.error('POST 请求失败:', error);
      }
    },

    /**
    * 处理接口数据
    * @param {*} data 
    */
    dealWithReportData(data) {
      let reportData = data;
      // 调节灵敏度可能为null
      if (!reportData.os_sen_f_right) reportData.os_sen_f_right = [];
      if (!reportData.od_sen_f_right) reportData.od_sen_f_right = [];
      if (!reportData.ou_sen_f_right) reportData.ou_sen_f_right = [];
      if (!reportData.os_sen_j_right) reportData.os_sen_j_right = [];
      if (!reportData.od_sen_j_right) reportData.od_sen_j_right = [];
      if (!reportData.ou_sen_j_right) reportData.ou_sen_j_right = [];

      // 处理调节幅度标准值
      reportData.ampStandardValue = 15 - 0.25 * reportData.age;
      reportData.cmpStandardValue = reportData.age <= 6 ? [3.0, 5.5] : reportData.age == 7 ? [3.5, 6.5] : reportData.age >= 8 && reportData.age <= 12 ? [5.0, 7.0] : reportData.age >= 13 && reportData.age <= 30 ? [8.0, 11.0] : [9.0, 12.0];

      // 处理调节灵敏度数据
      // 右眼
      if (reportData.od_sen_j_all.length > 0 || reportData.od_sen_f_all.length > 0) {
        reportData.rightTJLMD = this.dealWithTJLMD(reportData.od_sen_j_right, reportData.od_sen_j_all, reportData.od_sen_f_right, reportData.od_sen_f_all);
      } else {
        reportData.rightTJLMD = [];
      }

      // 左眼
      if (reportData.os_sen_j_all.length > 0 || reportData.os_sen_f_all.length > 0) {
        reportData.leftTJLMD = this.dealWithTJLMD(reportData.os_sen_j_right, reportData.os_sen_j_all, reportData.os_sen_f_right, reportData.os_sen_f_all);
      } else {
        reportData.leftTJLMD = [];
      }

      // 双眼
      if (reportData.ou_sen_j_all.length > 0 || reportData.ou_sen_f_all.length > 0) {
        reportData.bothTJLMD = this.dealWithTJLMD(reportData.ou_sen_j_right, reportData.ou_sen_j_all, reportData.ou_sen_f_right, reportData.ou_sen_f_all);
      } else {
        reportData.bothTJLMD = [];
      }
      this.setData({ reportData });
      this.setCtrlData(reportData);
      this.dealWithCheckResultText();

      // 对比敏感度
      if (reportData.ou_contrastsen_cpd != "" || reportData.od_contrastsen_cpd != "" || reportData.os_contrastsen_cpd != "") {
        let modeArr = {
          0: "default",
          1: "low",
          2: "middle",
          3: "high",
          4: "allSpf"
        }
        let mode = modeArr[reportData.contrastsen_spacefreq];
        let obj = {};
        obj.dbmgd = data.ou_contrastsen_cpd == "" ? "" : mode + "";
        obj.dbmgdl = data.os_contrastsen_cpd == "" ? "" : mode + "";
        obj.dbmgdr = data.od_contrastsen_cpd == "" ? "" : mode + "";
        for (let i = 0; i < 5; i++) {
          if (obj.dbmgd != "") obj.dbmgd += ("," + data.ou_contrastsen_cpd[i]);
          if (obj.dbmgdl != "") obj.dbmgdl += ("," + data.os_contrastsen_cpd[i]);
          if (obj.dbmgdr != "") obj.dbmgdr += ("," + data.od_contrastsen_cpd[i]);
        }
        this.dbmgdtext(obj);
        this.handleDbmgd(obj);
      }
      setTimeout(()=> {
        this.print();
      }, 2000)
    },

    //处理对比敏感度评估显示
    dbmgdtext(value) {
      let resText = { 'left': '', 'right': '', 'double': '' };
      //对比敏感度评估
      /**
       * 低频：[30,60,50,55,50]，[85,100,130,160,165]
       * 中频：[50,50,50,20,10]，[165,165,165,120,62]
       * 高频：[15,10,5,5,5]，[80,90,65,50,40]
       * 默认、全程：[35, 50, 50, 20, 5]，[65, 120, 120, 100, 60]
       * */
      if (!value.dbmgd && !value.dbmgdl && !value.dbmgdr) return;
      let text = "";
      //获取类型
      let type = "";
      if (value.dbmgd.length > 0) type = value.dbmgd.split(',')[0];
      else if (value.dbmgdl.length > 0) type = value.dbmgdl.split(',')[0];
      else if (value.dbmgdr.length > 0) type = value.dbmgdr.split(',')[0];
      //拆分数据
      let infol = value.dbmgdl.split(','); //左眼数据
      let infor = value.dbmgdr.split(','); //右眼数据
      let info = value.dbmgd.split(','); //双眼数据
      let left = [];
      let right = [];
      let doubl = [];
      let arr = [35, 50, 50, 20, 5]; //默认的最低值
      let spfArr = [1.5, 3, 6, 12, 18]; //默认的空间频率
      if (type === 'default') {
        arr = [35, 50, 50, 20, 5];
        spfArr = [1.5, 3, 6, 12, 18]; //默认的空间频率
      } else if (type === 'low') {
        arr = [30, 60, 50, 55, 50];
        spfArr = [0.5, 1.25, 2.0, 2.75, 3.5];
      } else if (type === 'middle') {
        arr = [50, 50, 50, 20, 10];
        spfArr = [4, 6.5, 9, 11.5, 14];
      } else if (type === 'high') {
        arr = [15, 10, 5, 5, 5];
        spfArr = [16, 22, 28, 34, 40];
      } else if (type === 'allSpf') {
        arr = [30, 20, 10, 5, 5];
        spfArr = [0.5, 10.5, 20.5, 30.5, 40];
      }

      if (value.dbmgdl.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (infol[i + 1] < arr[i]) left.push(i);
        }
      }
      if (value.dbmgdr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (infor[i + 1] < arr[i]) right.push(i);
        }
      }
      if (value.dbmgd.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (info[i + 1] < arr[i]) doubl.push(i);
        }
      }

      //判断是否选择了多种检查方式，如果不是，则显示的内容存在差异
      text = "";
      if (left.length > 0) {
        text += "左眼在";
        let result = this.createtext(spfArr, left);
        text += result;
      } else if (left.length === 0 && infol.length > 1) {
        text += "左眼在";
        text += spfArr[0] + "、" + spfArr[1] + "、" + spfArr[2] + "、" + spfArr[3] + "和" + spfArr[4] + " 这五个空间频率下的对比敏感度均正常。"
      }
      resText["left"] = text

      text = "";
      if (right.length > 0) {
        text += "右眼在";
        let result = this.createtext(spfArr, right);
        text += result;
      } else if (right.length === 0 && infor.length > 1) {
        text += "右眼在";
        text += spfArr[0] + "、" + spfArr[1] + "、" + spfArr[2] + "、" + spfArr[3] + "和" + spfArr[4] + " 这五个空间频率下的对比敏感度均正常。"
      }
      resText["right"] = text

      text = "";
      if (doubl.length > 0) {
        text += "双眼在";
        let result = this.createtext(spfArr, doubl);
        text += result;
      } else if (doubl.length === 0 && info.length > 1) {
        text += "双眼在";
        text += spfArr[0] + "、" + spfArr[1] + "、" + spfArr[2] + "、" + spfArr[3] + "和" + spfArr[4] + " 这五个空间频率下的对比敏感度均正常。"
      }
      resText["double"] = text
      this.setData({
        resText: resText
      })
      return resText;
    },

    //构造对比敏感度评估结果字符串
    createtext(arr1, arr2) {
      let length = arr2.length;
      let text = "";
      switch (length) {
        case 1:
          text += arr1[arr2[0]] + "这一个空间频率下的对比敏感度偏低。";
          break;
        case 2:
          text += arr1[arr2[0]] + "和" + arr1[arr2[1]] + "这两个空间频率下的对比敏感度偏低。"
          break;
        case 3:
          text += arr1[arr2[0]] + "、" + arr1[arr2[1]] + "和" + arr1[arr2[2]] + "这三个空间频率下的对比敏感度偏低。"
          break;
        case 4:
          text += arr1[arr2[0]] + "、" + arr1[arr2[1]] + "、" + arr1[arr2[2]] + "和" + arr1[arr2[3]] + "这四个空间频率下的对比敏感度偏低。"
          break;
        case 5:
          text += arr1[arr2[0]] + "、" + arr1[arr2[1]] + "、" + arr1[arr2[2]] + "、" + arr1[arr2[3]] + "和" + arr1[arr2[4]] + "这五个空间频率下的对比敏感度偏低。"
          break;
      }
      return text;
    },

    /**
     * 处理表格显隐
     */
    setCtrlData(reportData) {
      let isTJSensitivity = reportData.rightTJLMD.length > 0 || reportData.leftTJLMD.length > 0 || reportData.bothTJLMD.length > 0;
      let isSkd = reportData.four_lamp != ""; // 四孔灯
      let isZdy = reportData.leading_eye != ""; // 主导眼
      let isZjyw = reportData.horizontal_influence != "" && reportData.vertical_influence != ""; // 知觉眼位
      let isRhSjd = reportData.point_apart != "" && reportData.point_focus != ""; // 随机点融合
      let isLtLine = reportData.line_solid != ""; // 线条立体视
      let isLtStatic = reportData.s_random_point != ""; // 静态随机点
      let isLtDynamic = reportData.d_random_point != "";// 动态随机点
      let isDbmgd = reportData.ou_contrastsen_cpd != "" || reportData.od_contrastsen_cpd != "" || reportData.os_contrastsen_cpd != "";
      this.setData({
        ctrlTjShow: {
          isTJAmplitude: reportData.os_amp != "" && reportData.od_amp != "", // 调节幅度
          isTJSensitivity: isTJSensitivity, // 调节灵敏度
          isTJCpm: isTJSensitivity, // cpm
          isTJNervous: isTJSensitivity, // 紧张用时
          isTJRelax: isTJSensitivity, // 放松用时
          isDbmgd: isDbmgd, // 对比敏感度
        },
        ctrlBinocularShow: {
          // 一级视功能
          isBinocular1: isSkd || isZdy || isZjyw,
          isSkd: isSkd, // 四孔灯
          isZdy: isZdy, // 主导眼
          isZjyw: isZjyw, // 知觉眼位
          // 二级视功能
          isBinocular2: isRhSjd,
          isRhSjd: isRhSjd, // 随机点融合
          // 三级视功能
          isBinocular3: isLtLine || isLtStatic || isLtDynamic,
          isLtLine: isLtLine, // 线条立体视
          isLtStatic: isLtStatic, // 静态随机点
          isLtDynamic: isLtDynamic // 动态随机点
        }
      })
    },

    /**
     * 处理调节灵敏度
     * @param {Array} jRighrArr 
     * @param {Array} jAllArr 
     * @param {Array} fRightArr 
     * @param {Array} fAllArr 
     */
    dealWithTJLMD(jRighrArr = [], jAllArr = [], fRightArr = [], fAllArr = []) {
      let jfStatus = 0; // 0：全通过；1：负镜未通过；2：正镜未通过；3：全未通过；4：未操作
      if (jAllArr.length == 0 && fAllArr.length == 0) return;
      // cpm
      let cpm = (jRighrArr.length + fRightArr.length) / (60 - 0.57 * (jAllArr.length + fAllArr.length)) * 60 / 2;
      if (jAllArr.length + fAllArr.length == 1) {
        jfStatus = 4
        return ["0", "0", "0", jfStatus]
      }
      if (jRighrArr.length / jAllArr.length < 0.5) {
        // 负镜不通过（紧张）
        cpm = 0;
        jfStatus = 1;
      }
      if (fRightArr.length / fAllArr.length < 0.5) {
        // 正镜不通过（放松）
        cpm = 0;
        jfStatus = jfStatus == 1 ? 3 : 2;
      }

      // 调节灵敏度-紧张
      let jTime = 0;
      for (let j in jRighrArr) {
        jTime += parseFloat(jAllArr[jRighrArr[j]]);
      }
      jTime = jTime / jRighrArr.length || 0;

      // 调节灵敏度-放松
      let fTime = 0;
      for (let f in fRightArr) {
        fTime += parseFloat(fAllArr[fRightArr[f]]);
      }
      fTime = fTime / fRightArr.length || 0;
      return [jfStatus == 0 ? cpm.toFixed(1) : "0", jTime.toFixed(1), fTime.toFixed(1), jfStatus];
    },

    /**
     * 处理检查结果分析内容
     */
    dealWithCheckResultText() {
      let reportData = this.reportData;
      // 调节灵敏度合并单元格处理
      this.tjlmdMerge012 = this.tjlmdMerge01 = this.tjlmdMerge12 = this.tjlmdMerge02 = false;
      let bothStatus = reportData.bothTJLMD[0] >= reportData.cmpStandardValue[0];
      let rightStatus = reportData.rightTJLMD[0] >= reportData.cmpStandardValue[1];
      let leftStatus = reportData.leftTJLMD[0] >= reportData.cmpStandardValue[1];
      if (reportData.bothTJLMD.length + reportData.rightTJLMD.length + reportData.leftTJLMD.length == 12) {
        if (bothStatus == rightStatus && rightStatus == leftStatus) {
          this.tjlmdMerge012 = true
          this.tjlmdTemplateRows = '1fr'
        } else if (bothStatus == rightStatus) {
          this.tjlmdMerge01 = true
          this.tjlmdTemplateRows = '2fr 1fr'
        } else if (rightStatus == leftStatus) {
          this.tjlmdMerge12 = true
          this.tjlmdTemplateRows = '1fr 2fr'
        } else {
          this.tjlmdTemplateRows = '1fr 1fr 1fr'
        }
      } else if (reportData.bothTJLMD.length + reportData.rightTJLMD.length + reportData.leftTJLMD.length == 8) {
        if (bothStatus == rightStatus && reportData.leftTJLMD.length == 0) {
          this.tjlmdMerge01 = true
          this.tjlmdTemplateRows = '1fr'
        } else if (rightStatus == leftStatus && reportData.bothTJLMD.length == 0) {
          this.tjlmdMerge12 = true
          this.tjlmdTemplateRows = '1fr'
        } else if (bothStatus == leftStatus && reportData.rightTJLMD.length == 0) {
          this.tjlmdMerge02 = true
          this.tjlmdTemplateRows = '1fr'
        } else {
          this.tjlmdTemplateRows = '1fr 1fr'
        }
      } else {
        this.tjlmdTemplateRows = '1fr'
      }
    },

    /**
     * 
     * @param {Array} arr 短句
     * @param {string} middleMark 间隔符号
     * @param {string} lastMark 尾号
     */
    insertPunctuation(arr, middleMark, lastMark) {
      let text = "";
      for (let i in arr) {
        text += arr[i] + (i < arr.length - 1 ? middleMark : lastMark);
      }
      return text;
    },
    setData(obj) {
      for (let i in obj) {
        this[i] = obj[i];
      }
    },
    async print() {
      if (!this.$refs.printBox) return;
      if (this.ctrlTjShow.isDbmgd) {
        // 假设每英寸的像素数为96px，通常这个值取决于显示器分辨率
        const dpi = 96;
        const inchToCm = 2.54;
        // 获取元素高度（像素值）
        const printBoxHeightPx = this.$refs.printBox.clientHeight;
        // 将高度转换为厘米
        const printBoxHeightCm = printBoxHeightPx / dpi * inchToCm;
        // A4纸的可打印高度（假设边距为2.54cm，即1英寸）
        const printableHeightCm = 29.7 - (2 * 2.54);
        console.log(printBoxHeightPx, printBoxHeightCm, printableHeightCm)
        if (printBoxHeightCm > printableHeightCm) {
          // 提供预览
          var style = document.createElement('style');
          style.id = 'printStyle'; // 给这个样式元素一个ID，方便后续移除
          style.innerHTML = "table{ border-right: 1px solid #000;border-bottom: 1px solid #000;font-size:11px;border-collapse: collapse;}.dbmgd-normal{width:300px;height:188px;padding:4px 15px 0px 15px;}.user-info{font-size:11px;margin:0 auto;margin-top:16px;}.card-title{margin-top:12px;margin-bottom:4px;font-size:13px;}.table-class{margin-bottom:4px;}.item{padding:2px 8px;}.report-footer{margin-top:12px;font-size:11px;}.suggest-class{margin-top:0px;font-size:11px;}.suggest-class div:nth-child(2){margin-top:0px;}.cpm-font-size{font-size:11px;}.report-title{font-size:14px;margin-bottom:8px;}.dbmgd-no-print{display:none;}@page{margin:0.5cm 2px 0cm 0cm !important;}"
          document.head.appendChild(style);
        }
      }
      const clonedBody = document.getElementById('printBox').cloneNode(true);
      let dom = "";
      const styleTags = document.querySelectorAll('style');
      styleTags.forEach((styleTag, index) => {
        let style = `<style>${styleTag.innerHTML}</style>`.replace(/(\r\n|\n|\r)/gm, '');
        dom += style.replace(/^\s*[\r\n]/gm, '');
      });
  
      dom += '<style>.print-box{ visibility: visible; }</style>'
      dom += clonedBody.outerHTML;
      wx.miniProgram.postMessage({ data: dom })
      wx.miniProgram.navigateBack();

      // try {
      //   let postData = {
      //     title: "检查报告",
      //     content: dom,
      //     source: "dbs_web"
      //   };
      //   postData = Qs.stringify(postData);
      //   const config = {
      //     headers: {
      //       'Content-Type': 'application/x-www-form-urlencoded' // 这里是常见的 Content-Type
      //     },
      //     data: postData,
      //     method: 'post',
      //     url: '/doboso/netserver/hospital/pdf.php',
      //     responseType: 'blob'
      //   };
      //   const response = await axios(config);
      //   const link = document.createElement('a');
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   link.style.display = 'none';
      //   link.href = URL.createObjectURL(blob);
      //   link.setAttribute('download', `贝贝乐VR视功能检查报告单.pdf`);
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link)

      //   const fr = new FileReader();
      //   fr.readAsDataURL(blob);
      //   const message = fr.result
      //   console.log(message)
      // } catch (error) {
      //   // 处理错误
      //   console.error('POST 请求失败:', error);
      // }
    },

    //处理对比敏感度数据并且显示
    handleDbmgd(res) {
      let options = Dbmgd(res);
      let div = document.createElement('div');
      div.style.width = 450 + 'px';
      div.style.height = 300 + 'px';
      document.body.appendChild(div);
      let echart = this.$echarts.init(div);
      echart.setOption(options, true, false);
      this.dbmgdSrc = echart.getConnectedDataURL({
        pixelRatio: 2, //导出的图片分辨率比率,默认是1
        backgroundColor: "#fff", //图表背景色
        type: "png" //图片类型支持png和jpeg
      });
      document.body.removeChild(div);
    },
    transBlobFilrToFile(blob) {
      // 使用 File 构造函数将 Blob 转换为 File
      const file = new File([blob], '贝贝乐VR视功能检查报告单', { type: blob.type, lastModified: Date.now() });
      return file;
    }
  },
  data() {
    return {
      env: 'release',// develop:开发版、trial:体验版、release:正式版,
      domTitle: "光朗管理系统",
      cpmJFStatus: ["", "负镜不通过", "正镜不通过", "正负镜均不通过", "未操作"],// 0：全通过；1：负镜未通过；2：正镜未通过；3：全未通过；
      reportList: [],
      reportData: {},
      showReport: false,
      ctrlTjShow: {
        isTJAmplitude: false, // 调节幅度
        isTJSensitivity: false, // 调节灵敏度
        isTJCpm: false, // cpm
        isTJNervous: false, // 紧张用时
        isTJRelax: false, // 放松用时
        isDbmgd: false, // 对比敏感度
      },
      ctrlBinocularShow: {
        // 一级视功能
        isBinocular1: false,
        isSkd: false, // 四孔灯
        isZdy: false, // 主导眼
        isZjyw: false, // 知觉眼位
        // 二级视功能
        isBinocular2: false,
        isRhSjd: false, // 随机点融合
        // 三级视功能
        isBinocular3: false,
        isLtLine: false, // 线条立体视
        isLtStatic: false, // 静态随机点
        isLtDynamic: false // 动态随机点
      },
      nickname: "",
      channel_name: "",
      tjlmdMerge01: false,
      tjlmdMerge12: false,
      tjlmdMerge02: false,
      tjlmdMerge012: false,
      tjlmdTemplateRows: "1fr 1fr 1fr",
      resText: { 'left': '', 'right': '', 'double': '' },
      dbmgdSrc: "",
      showPrintButton: false
    }
  }
};
</script>
<style lang="scss" >
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
table {
  border-spacing: 0px;
  -webkit-border-collapse: collapse;
  border-collapse: collapse; 
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  font-size: 12px;
  color: #000;
  border-top: none;
  border-left: none;
}

.table-class, .result-grid-contain, .table-function-container-title, .table-function-container, .result-box, .table-class tr, .result-box tr {
  width: 100%;
}

td {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: none;
  border-bottom: none;
}

.print-box {
  padding-left: 28px;
  padding-right: 28px;
  visibility: hidden;
}

.user-info {
  border: none;
  width: 100%;
  font-size: 12px;
  margin: 8px auto;
  color: #000;
  text-align: center;

  td {
    border: none;
    width: 25%;
  }
}

.card-title {
  position: relative;
  font-weight: 500;
  color: #1D2129;
  text-align: left;
  font-style: normal;
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: 15px;
}

.table-class {
  margin-bottom: 8px;
}

.table-function-container-title {
  color: #666;
}

.table-function-container {
  color: #666;
}

.dbmgd-normal {
  width: 400px;
  height: 250px;
  padding: 8px 15px 0px 15px;
}

.report-title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}


.report-footer {
  margin-top: 24px;
  color: #000;
  font-style: normal;
  font-size: 12px;
  text-align: right;
  width: 100%;
}

.table-binocular-container {
  color: #666;
}

.table-function-container-title>.item,
.table-function-container-title>div>.item {
  background-color: rgba(247, 248, 250, 1);
  font-weight: 400;
  color: #000;
  font-style: normal;
}

.table-function-container-title>div .item {
  color: #000;
}

.table-function-container-title .item {
  background-color: rgba(247, 248, 250, 1);
}

.tjlmd-class {
  height: 50px;
  line-height: 10px;
}

.item {
  text-align: center;
  color: #000;
  overflow: hidden;
  padding: 2px 8px;
}

.table-class .highlight-down-text div,
.table-class .highlight-down-text div,
.table-class .highlight-up-text div,
.table-class .highlight-up-text div {
  display: inline;
}

.suggest-class {
  font-size: 12px;
  margin-top: 8px;
  color: #000;
}

.highlight-color {
  color: #000;
}

.cpm-font-size {
  font-size: 12px;
}

.highlight-down-text {
  color: #000;
}

.highlight-down-text::after {
  content: '↓';
  display: inline-block;
  color: #000;
}

.highlight-up-text {
  color: #000;
}

.highlight-up-text::after {
  content: '↑';
  display: inline-block;
  color: #000;
}

.just-for-print {
  display: block;
}

.result-grid-contain .align-center {
  text-align: center;
}

.result-grid-contain .align-left {
  text-align: left;
}

.result-grid-contain .green-bg {
  background: #a1c755;
  -webkit-print-color-adjust: exact;
  color: #fff;
}

.orange-bg {
  -webkit-print-color-adjust: exact;
  background: #ff6a00;
  color: white;
}

.red-bg {
  background: #eb1909;
  -webkit-print-color-adjust: exact;
  color: white;
}

.btn-class {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 30px;
  background: #007aff;
  border-radius: 8px;
  width: 110px;
  font-weight: 400;
  font-size: 20px;
  color: #fafcff;
  height: 30px;
  font-size: 18px;
  text-align: center;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
@page {
  margin: 1cm 2px 0cm 0cm !important;
}
</style>
