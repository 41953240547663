var legend = []; //标志
var xAxis = [1.5, 3, 6, 12, 18];
var series = [];
// var flag = false;
let legendColor = [];
export function Dbmgd(res) {
    series = [];
    legendColor = [];
    legend = [];
    // flag = false;
    let arr = [res.dbmgd, res.dbmgdr, res.dbmgdl,];
    let type = '';
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] != '') {
            initDbmgd(arr[i], i);
            type = arr[i].split(',')[0];
        }
        if (i == 2) {
            //获取对比敏感度类型并传值
            if(type !== ''){
                addSeries(type);
            }
            // if (type == 'default' || res.dbmgd == 'default' || res.dbmgdl == 'default' || res.dbmgdr == 'default')
            let datas = drawLine();
            return datas;
        }
    }
}

function initDbmgd(res, name) {
    let color = null;
    let symbol = "circle";
    if (name == 0) {
        name = '双眼';
        color = ' #1ef60a';
    }
    if (name == 1) {
        name = '右眼';
        color = '#ff0000';
        symbol = "rect";
    }
    if (name == 2) {
        name = '左眼';
        color = '#0000ff';
        symbol = "diamond";
    }
    legend.push(name);
    let obj = {};
    obj.name = name;
    obj.type = "line";
    obj.symbol = symbol;
    obj.symbolSize = 8;
    if(name == 0){
        obj.symbolSize = 9;
    }
    let data = res.split(',');
    let type = data.shift();
    if (data.length != 5) {
        let arr = [0, 0, 0, 0, 0];
        let tem = arr.slice(0, 5 - data.length);
        for (let i in tem) {
            data.push(tem[i]);
        }
    }
    for (let i in data) {
        data[i] = Number(data[i]);
    }
    obj.data = data;
    obj.lineStyle =  { width: 3, color: color};
    legendColor.push(color);
    series.push(obj);

    switch (type) {
        case "low":
            xAxis = [0.5, 1.25, 2.0, 2.75, 3.5];
            break;
        case "middle":
            xAxis = [4, 6.5, 9, 11.5, 14];
            break;
        case "high":
            xAxis = [16, 22, 28, 34, 40];
            break;
        case "allSpf":
            xAxis = [0.5, 10.5, 20.5, 30.5, 40];
            break;
        case "default":
            xAxis = [1.5, 3, 6, 12, 18];
            break;
    }
}

//对比敏感度评估
/**
 * 低频：[30,60,50,55,50]，[85,100,130,160,165]
 * 中频：[50,50,50,20,10]，[165,165,165,120,62]
 * 高频：[15,10,5,5,5]，[80,90,65,50,40]
 * 全程：[30,20,10,5,5],[85,120,90,50,40]
 * 默认：[35, 50, 50, 20, 5]，[65, 120, 120, 100, 60]
 * */
function addSeries(type) {
    let data1;
    let data2;
    if(type === 'default'){
        data1 = [35, 50, 50, 20, 5];
        data2 = [100, 170, 170, 120, 65];
    }else if(type === 'low'){
        data1 = [30,60,50,55,50];
        data2 = [85,100,130,160,165];
    }else if(type === 'middle'){
        data1 = [50,50,50,20,10];
        data2 = [165,165,165,120,62];
    }else if(type === 'high'){
        data1 = [15,10,5,5,5];
        data2 = [80,90,65,50,40];
    }else if(type === 'allSpf'){
        data1 = [30,20,10,5,5];
        data2 = [85,120,90,50,40];
    }
    //阴影线条的高度需要减去下面线条的高度
    for(let i = 0; i< data2.length ;i++){
        data2[i] = data2[i] - data1[i];
    }
    // console.log()
    // if (flag) return;
    series.push({ name: "最低", type: "line", data: data1, stack: "正常", symbol: "none", lineStyle: { width: 0.5, color: "#eee" } } );
    series.push({ name: "最高", type: "line", data: data2, stack: "正常", symbol: "none", areaStyle: { color: 'rgb(16,16,16)', opacity: 0.5 }, lineStyle: { width: 0.5, color: "#eee" } , })
}

function drawLine() {
    let option = {
        animation: false,
        tooltip: {
            trigger: "axis",
            show: false
        },
        grid: {
            left: '5%',
            right: '4%',
            bottom: '8%',
            top: '3%',
            containLabel: true
        },
        color: legendColor,
        legend: {
            x: '80%',
            y: '10',
            data: legend,
            itemWidth: 8,
            // icon: 'circle',
            textStyle: {
                lineHeight: 15
            }
        },
        xAxis: {
            name: '空间频率',
            nameLocation: 'middle',
            nameTextStyle: {
                fontSize: 12,
                padding: 10
            },
            data: xAxis,
            axisTick: { //y轴刻度线
                show: false
            },
            // boundaryGap: false,
            min: 0,
            type: 'category',
            axisLine: { 
                onZero: false,
                lineStyle: {
                    color: '#000000'
                }
            }
        },
        yAxis: {
            name: '对比敏感度',
            nameLocation: 'middle',
            nameTextStyle: {
                fontSize: 12,
                padding: 12
            },
            axisTick: { //y轴刻度线
                show: true
            },
            splitLine: { show: false }, //去除网格线
            splitArea: {
                show: true,
                areaStyle: {
                    color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.7)']
                }
            },
            min: -5,
            type: 'value',
            axisLine: { 
                show: true,
                onZero: false,
                lineStyle: {
                    color: '#000000'
                }
            },
            axisLabel: {
                formatter(value) {
                    if (value < 0) return "";
                    else return value;
                }
            },
        },
        series: series
    }
    return option;
}